<template>
  <b-card class="login-body mb-5">

    
    <div class="d-flex justify-content-center mb-8 mt-8 heading-container">
      <span class="svg-icon svg-icon-md text-danger">
        <i class="fa fa-lock color-danger"></i>
      </span>
      <h4 style="margin-left: 10px;">{{ $t('AUTH.ADMINISTRATOR') }}</h4>
    </div>

    <div v-if="show_initializing" class="d-flex justify-content-center mb-8 mt-8">
      
      <b-spinner label="Loading..."></b-spinner>
    
    </div>



    <div v-else-if="show_invalid_link" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
      <div class="alert-icon"><i class="flaticon-warning"></i></div>
      <div class="alert-text"><p>{{$t('ACCOUNT.INVALID_LINK')}}</p></div>
    </div>

    <div v-else>

      <!-- if settings has both email and bankid -->
      <v-tabs v-if="company_settings.enable_login_email && company_settings.enable_login_bankid" background-color="white" color="accent-4" left>
        <v-tab>{{$t('ACCOUNT.TAB_BANKID')}}</v-tab>
        <v-tab>{{$t('ACCOUNT.TAB_EMAIL')}}</v-tab>
        
        <v-tab-item>
          <BankIdLoginComponent 
            class="mt-6"
            :company_id="company_id"
          />
        </v-tab-item>
        
        <v-tab-item>
          <EmailPasswordLoginComponent 
            class="mt-6"
            :company_id="company_id"
          />
        </v-tab-item>
      </v-tabs>

      <!-- if settings has only email -->
      <div v-else-if="company_settings.enable_login_email">
        <EmailPasswordLoginComponent 
          :company_id="company_id"
        />
      </div>

      <!-- if settings has only bankid -->
      <div v-else-if="company_settings.enable_login_bankid">
        <BankIdLoginComponent 
          :company_id="company_id"
        />
      </div>

    </div>

  </b-card>
</template>

<style lang="scss" scoped>

.heading-container {
  border-radius: 0.42rem;
  
  border: 1px solid #d7ddff;
  margin-top: 2px;
  padding: 0.5rem 1rem;
  padding-top: 12px;
  margin-bottom: 1rem;
  
}

.login-body {
  
  border: 1px solid #d7ddff;
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { mapState } from 'vuex';
import { LOGIN } from '@/core/services/store/auth.module';
import { SET_USER_ID, SET_COMPANY_ID } from '@/core/services/store/common.module';
import { SET_IS_SUPERADMIN, SET_IS_THS, LOGOUT, SET_ULOGIN_LINK } from '@/core/services/store/auth.module';

import axios from 'axios';

import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import store from '@/core/services/store';
import { hasAccessToPageForCurrentCompany, initFromLogin, getAnyPageWithAccess, clearCurrentCompanyId } from '@/core/services/companyAccess';
import BankIdLoginComponent from '@/view/pages/ml/auth/Login/BankIdLoginComponent.vue';
import EmailPasswordLoginComponent from '@/view/pages/ml/auth/Login/EmailPasswordLoginComponent.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  mixins: [validationMixin, toasts ],
  name: 'tabbedlogin',
  components: {
    BankIdLoginComponent,
    EmailPasswordLoginComponent
  },

  data() {
    return {

      show_initializing: true,

      show_invalid_link: false,

      company_id: null,

      company_settings: {}
    };
  },
 
  async mounted() {

    this.company_id = this.$route.params.company_id;

    if (!this.company_id) {

      const settings = await axios.get(`/system/public`);

      this.company_id = settings.data.company_id;
    }

    const login_link = `/ml-main-login/${this.company_id}`;

    this.$store.dispatch(SET_ULOGIN_LINK, login_link);

    this.$store.dispatch(SET_COMPANY_ID, this.company_id);

    this.company_settings = await this.get_settings(this.company_id);
  },
  methods: {

    async get_public_data() {
      try {
        const res = await axios.get(`/company/public-data`);

        if (res.status === 200) {
          return res.data;
        }

      }
      catch (err) {
        console.error('get_public_data', err);
      }

      return null;
    },

    async get_settings(company_id) {
      try {
        console.log('getting company ' + company_id);
        const res = await axios.get(`/company/public/${company_id}`);

        if (res.status === 200) {
          this.show_initializing = false;

          return res.data.company_settings;
        }

      }
      catch (err) {
        console.error('get_settings', err);
      }

      this.show_initializing = false;

      this.show_invalid_link = true;

      return null;
    },

  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
